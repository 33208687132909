import React, { useEffect } from 'react'
import Navbar from '../../Pages/components/Navbar';
import Sidenav from './Sidenav';
import { Box, Card, CardContent, Grid2 as Grid, Stack, Typography } from '@mui/material';
import CountUp from 'react-countup';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Send } from '@mui/icons-material';
import { fetchWorks, fetchWorksCompleted, fetchWorksCompletedInfra1, fetchWorksCompletedInfra2, fetchWorksDelay, fetchWorksDelayInfra1, fetchWorksDelayInfra2, fetchWorksInfra1, fetchWorksInfra2, fetchWorksOnhold, fetchWorksOnholdInfra1, fetchWorksOnholdInfra2, fetchWorksPending, fetchWorksPendingInfra1, fetchWorksPendingInfra2, fetchWorksUnderProgress, fetchWorksUnderProgressInfra1, fetchWorksUnderProgressInfra2 } from '../../Redux/workReducer';

const SeDashboard = () => {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(fetchWorks())

    dispatch(fetchWorksUnderProgress())
    dispatch(fetchWorksDelay())
    dispatch(fetchWorksCompleted())
    dispatch(fetchWorksOnhold())
    dispatch(fetchWorksPending())

    dispatch(fetchWorksUnderProgressInfra1())
    dispatch(fetchWorksDelayInfra1())
    dispatch(fetchWorksCompletedInfra1())
    dispatch(fetchWorksOnholdInfra1())
    dispatch(fetchWorksPendingInfra1())

    dispatch(fetchWorksUnderProgressInfra2())
    dispatch(fetchWorksDelayInfra2())
    dispatch(fetchWorksCompletedInfra2())
    dispatch(fetchWorksOnholdInfra2())
    dispatch(fetchWorksPendingInfra2())



    dispatch(fetchWorksInfra1())
    dispatch(fetchWorksInfra2())
      },[dispatch])
      const works = useSelector((state) => state.works.works);

      const worksUnderProgressCount = useSelector((state) => state.works.worksUnderProgress[0]?.twcount);
      const worksDelayCount = useSelector((state) => state.works.worksDelay[0]?.twcountDelay);
      const worksCompletedCount = useSelector((state) => state.works.worksCompleted[0]?.twcountCompleted);
      const worksOnholdCount = useSelector((state) => state.works.worksOnhold[0]?.twcountOnhold);
      const worksPendingCount = useSelector((state) => state.works.worksPending[0]?.twcountPending);
      
      const worksUnderProgressCountInfra1 = useSelector((state) => state.works.worksUnderProgressInfra1[0]?.twcount);
      const worksDelayCountInfra1 = useSelector((state) => state.works.worksDelayInfra1[0]?.twcountDelay);
      const worksCompletedCountInfra1 = useSelector((state) => state.works.worksCompletedInfra1[0]?.twcountCompleted);
      const worksOnholdCountInfra1 = useSelector((state) => state.works.worksOnholdInfra1[0]?.twcountOnhold);
      const worksPendingCountInfra1 = useSelector((state) => state.works.worksPendingInfra1[0]?.twcountPending);
      
      const worksUnderProgressCountInfra2 = useSelector((state) => state.works.worksUnderProgressInfra2[0]?.twcount);
      const worksDelayCountInfra2 = useSelector((state) => state.works.worksDelayInfra2[0]?.twcountDelay);
      const worksCompletedCountInfra2 = useSelector((state) => state.works.worksCompletedInfra2[0]?.twcountCompleted);
      const worksOnholdCountInfra2 = useSelector((state) => state.works.worksOnholdInfra2[0]?.twcountOnhold);
      const worksPendingCountInfra2 = useSelector((state) => state.works.worksPendingInfra2[0]?.twcountPending);
      
      const worksInfra1Count=useSelector((state) => state.works.worksInfra1);
      const worksInfra2Count=useSelector((state) => state.works.worksInfra2);

  return (
    <div>
    <Navbar /> <Box height={70} />
    <Box sx={{ display: "flex" }}>
      <Sidenav />
 
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant='h4' align='center' py={2}>SE Dashbaord</Typography><hr/>
      <Box height={20}/>
   
      <Grid spacing={2} container>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Infra-I
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/se/projectsInfra1")}
                  >
                 View All Infra-I Works<Send/>:{" "}
                    {/* <CountUp delay={0.3} end={orgus.length} duration={2.9} /> */}
                    <CountUp delay={0.3} end={worksInfra1Count.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    INFRA-II
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/se/projectsInfra2")}
                  >
                 View All Infra-II Projects/Works<Send/>:{" "}
                    {/* <CountUp delay={0.3} end={orgus.length} duration={2.9} /> */}
                    <CountUp delay={0.3} end={worksInfra2Count.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid size={4}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Mobility
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/se/mobilitywork")}
                  >
                 View All Mobility Projects/Works<Send/>:{" "}
                    {/* <CountUp delay={0.3} end={orgus.length} duration={2.9} /> */}
                    <CountUp delay={0.3} end={worksInfra2Count.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
      
          {/* <Grid item xs={8}>
              <Stack spacing={2} direction={"row"}>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white"}} >
            sx={{ maxWidth: 345, height:300 }}
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" color={'lightblue'}>
                  ALL Projects: <CountUp delay={0.3} end= "50" duration={2.9} />
              
                </Typography>
                <Typography gutterBottom variant="h5" component="div" color={'lightgreen'}>
                  Active Project: <CountUp delay={0.3} end= "45" duration={2.9} />
                   <CountUp delay={0.3} end= {activeCount} duration={2.9} />
                </Typography>
                <Typography gutterBottom variant="h5" component="div" color={'#fe5644'}>
                  InActive Project: <CountUp delay={0.3} end= "5" duration={2.9} />
            
                </Typography>
               
              </CardContent>
            
            </Card>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
              
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
               Underprogress Projects:<CountUp delay={0.3} end= "42" duration={2.9} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
               
                </Typography>
              </CardContent>
            
            </Card>
            <Card  sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
              
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
               Completed Projects:<CountUp delay={0.3} end= "6" duration={2.9} />
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  projects <CountUp delay={0.3} end= {orgus.length} duration={2.9} /> 
              projects <CountUp delay={0.3} end= "8" duration={2.9} />
                </Typography>
              </CardContent>
            
            </Card>
            </Stack>
          </Grid>
          <Grid item xs={4}>
              <Stack spacing={2}>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
                Delay Projects: 
               </Typography>
               <Typography variant="body2" color="#ffffff">
                <CountUp delay={0.3} end= "6" duration={2.9} />
               </Typography>
             </CardContent>
           
           </Card>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
            Pending Projects:
               </Typography>
               <Typography variant="body2" color="#ffffff" >
               <CountUp delay={0.3} end= "2" duration={2.9} />
               </Typography>
             </CardContent>
           
           </Card>
           </Stack>
          </Grid> */}
          <Box height={20} />
        </Grid>
      </Box>
    </Box>
  </div>
  );
}

export default SeDashboard