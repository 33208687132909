import React, { useEffect } from 'react'
import WorkList from '../../Xen/components/WorkList'
import { Box, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { fetchWorks } from '../../Redux/workReducer'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { Link } from 'react-router-dom'

const ProjectList = () => {

  const works = useSelector((state) => state.works.works);
  const dispatch=useDispatch();
  useEffect(()=>{
dispatch(fetchWorks())
  },[dispatch])

  const columns = [
    { field: "id", headerName: "ID",  },
    { field: "workname", headerName: "Work Name",
      width:400,
      renderCell: (params) =>  <Link to={`/contractor/cwork/${params.id}`}>{params.value}</Link>
        // <Link to="/contractor/cwork/">{params.row.workname}</Link>,
    },
    { field: "agencyName", headerName: "Agency Name"},
    { field: "startDate", headerName: "Start Date"},
    { field: "timeLimit", headerName: "Time Limit"},
    { field: "dlp", headerName: "DLP"},
    { field: "sdDateComplition", headerName: "Date of Complition"},
    { field: "srDateComplition", headerName: "Rivised Date of Complition"},
    { field: "budgetDYear", headerName: "Budget During Year"},
    { field: "phyStatus", headerName: "Physical Work Status(%) "},
    { field: "finStatus", headerName: "Finacial Work Status(%)"},   

    { field: "description", headerName: "Description" },   
  
  ];


  return (
    <div>

     <Box height={70}/>
     <Box>
     <div style={{ height: 400, width: '100%'  }}>
        <DataGrid
          rows={works}
          columns={columns}
         // getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          slots={{ toolbar: GridToolbar }}
          getRowHeight={() => 'auto'}
          //checkboxSelection
        />
      </div>
          </Box>
        </div>
  )
}

export default ProjectList