import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchWorksCreatedBy } from '../../Redux/workReducer';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box } from '@mui/material';

const XenWorkList = () => {
    const dispatch = useDispatch();
  const[wstatus,setWstatus]=useState();
  const works = useSelector((state) => state.works.worksCreatedBy);
  const [selectedWork, setSelectedWork] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [active, setActive] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState("");
  const [currentWorkId, setCurrentWorkId] = useState(null);

  useEffect(() => {
   

    dispatch(fetchWorksCreatedBy());
  }, [dispatch]);

  const handleEdit = (post) => {
    setSelectedWork(post);
    setModalOpen(true);
  };

//   const handleDeleteWork = (id) => {
//     dispatch(deleteWork(id))
//       .then(() => {
//         toast.success("Iten deleted successfully!");
//       })
//       .catch((error) => {
//         toast.error("Failed to add post!");
//       });
//   };

//   const handleAdd = () => {
//     setModalOpen(true);
//     dispatch(fetchWorksCreatedBy());
//     setSelectedWork(null);
//   };
//   const handleChangeStatus = (id, active) => {
//     dispatch(changeWorkStatus({ id, active }))
//       .then(() => {
//         toast.success("Work status updated successfully!");
//         dispatch(fetchWorksCreatedBy());
//       })
//       .catch((error) => {
//         toast.error("Failed to update work status!");
//       });
//   };


//   const handleUpdateWorkStatus = (id,wstatus) => {
//     dispatch(updateWorkStatus({ id, wstatus }))
//       .then(() => {
//         toast.success("Work status updated successfully!");
//         dispatch(fetchWorksCreatedBy());
//       })
//       .catch((error) => {
//         toast.error("Failed to update post status!");
//       });
//   };

//   const handleDialogOpen = (type, workId) => {
//     setDialogType(type);
//     setCurrentWorkId(workId);
//     setDialogOpen(true);
//   };

//   const handleDialogClose = () => {
//     setDialogOpen(false);
//     setCurrentWorkId(null);
//     setDialogType("");
//   };

//   const handleConfirmAction = () => {
//     if (dialogType === "delete") {
//       handleDeleteWork(currentWorkId);
//     }
//     handleDialogClose();
//   };

  const columns = [
    { field: "id", headerName: "ID",  },
    { field: "fileno", headerName: "File No.",  },
    { field: "aramt", headerName: "Admin Amount in Cr.",  },
    { field: "adminamt", headerName: "Allotted/Restricted Amount in Cr.",  },
    { field: "byamt", headerName: "Budget Year Amount in Cr.",  },
    { field: "workname", headerName: "Work Name",
      width: 300,
      renderCell: (params) => (
        <div
          style={{
            textAlign: "justify", // Inline style for justification
            whiteSpace: "normal", // Allow text wrapping
            wordBreak: "break-word", // Break long words
          }}
        >
        {params.row.workname}
        </div>
      )
    // renderCell: (params) => (
    //   <Box
    //   sx={{
    //     maxWidth:'auto',
    //       whiteSpace: 'normal',      
    //       wordWrap: 'break-word',   
    //       overflowWrap: 'break-word',
    //       padding: '5px',            
    //     }}
    //   >
    //     {params.row.workname}
    //   </Box>
    // ),

      
    //   renderCell: (params) => 
        
    //     <Link to={`/xen/xvisit/${params.row.id}`}>{params.row.workname}</Link>
    },
    { field: "wstatus", headerName: "Work Status",  },
    { field: "vender", headerName: "Agency Name",
      // renderCell: (params) => params?.row?.vender?.vendername ||'none',
      renderCell: (params) => params?.row?.vender || "no vender",
    },
    { field: "startDate", headerName: "Start Date"},
    { field: "timeLimit", headerName: "Time Limit"},
    { field: "dlp", headerName: "DLP"},
    { field: "sdDateComplition", headerName: "Date of Complition"},
    { field: "srDateComplition", headerName: "Rivised Date of Complition"},
    { field: "budgetDYear", headerName: "Budget During Year"},
    { field: "phyStatus", headerName: "Physical Work Status(%) ",
      renderCell: (params) => 
        <> {params.row.phyStatus}{'%'}
        </>
       
    },
    { field: "finStatus", headerName: "Finacial Work Status(%)"

      ,
      renderCell: (params) => 
        <> {params.row.finStatus}{'%'}
        </>
    },     

    { field: "description", headerName: "Description" },
    { field: "created", headerName: "Created On" },
    // {
    //   field: "Status",
    //   headerName: "Status",
    //   description: "This column has a value getter and is not sortable.",
    //   IconButton: <DeleteIcon />,

     
    //   renderCell: ({ row }) => <Switch checked={row.active} onChange={(e) => handleChangeStatus(row.id, e.target.checked)}/>,
 
    // },

    // {
    //   field: "Edit",
    //   headerName: "Edit",
    //   description: "This column has a value getter and is not sortable.",
    //   IconButton: <EditIcon />,
     
    //   renderCell: ({ row }) => (
    //     <IconButton onClick={() => handleEdit(row)}>
    //       <EditIcon />
    //     </IconButton>
    //   ),
    // },

    // {
    //     field: 'Delete',
    //   headerName: 'Delete',
    //   description: "This column has a value getter and is not sortable.",
    //   IconButton: <DeleteIcon />,

      
    //   renderCell: ({ row }) => (
    //     <IconButton onClick={() => handleDialogOpen("delete", row.id)}>
    //       <DeleteIcon />
    //     </IconButton>
    //   ),
    // },
    // {
    //   field: "Project Status",
    //   headerName: "Project Status",
    //   description: "This column has a value getter and is not sortable.",
    //   IconButton: <DeleteIcon />,
    //   renderCell: ({ row }) =>  <Box sx={{ minWidth: 120 }}>
    //   <FormControl fullWidth>
    //     <InputLabel id="demo-simple-select-label">Work Status</InputLabel>
    //     <Select
    //       labelId="demo-simple-select-label"
    //       id="demo-simple-select"
    //       value={wstatus}
    //       label="Work Status"
    //       onChange={(e)=>handleUpdateWorkStatus(row.id, e.target.value)}
    //     >
    //       <MenuItem value="Pending">Pending</MenuItem>
    //       <MenuItem value="UnderProgress">Under Progress</MenuItem>
    //       <MenuItem value='Delay'>Delay</MenuItem>
    //       <MenuItem value='Completed'>Completed</MenuItem>
    //       <MenuItem value='Parked'>On Hold</MenuItem>
    //     </Select>
    //   </FormControl>
    // </Box>,
 
    // },
    

  ];
  return (
    <div style={{ height: 'auto', width: '100%' }}>
       <DataGrid
          rows={works}
          columns={columns}
          getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          slots={{ toolbar: GridToolbar }}
          getRowHeight={() => 'auto'}
          //checkboxSelection
        />
    </div>
  );
}

export default XenWorkList;
