import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AdminApp from '../Ceo/components/AdminApp';

import File from '../Xen/components/File';
import Xen from '../Xen/components/Xen';
import Xendashboard from '../Xen/components/Xendashboard';
import Vender from '../Xen/components/Vender';
import Work from '../Xen/components/Work';
import XenProjects from '../Xen/components/XenProjects';
import XenRemarks from '../Xen/components/XenRemarks';
import Assignproject from '../Xen/components/Assignproject';
import Xvisit from '../Xen/components/Xvisit';


const CeoRouter = () => {
  return (
    <div>
      <Routes>
            <Route path='/*' element={<Xen />}/>
            <Route path='/dashboard' element={<Xendashboard />}/>
            <Route path='/vender' element={<Vender />}/>
            <Route path='/work' element={<Work />}/>
            <Route path='/xvisit/:workId' element={<Xvisit />}/>
            <Route path='/assignprojects' element={<Assignproject />}/>
            <Route path='/fileup' element={<File  />}/>
           
            <Route path='/projectsxen' element={<XenProjects />}/>

        </Routes>
    </div>
  );
}

export default CeoRouter;
