import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Box, TextField, Switch, FormControlLabel, Typography, Grid2 as Grid } from '@mui/material';
import { addVender, updateVender } from '../../Redux/venderReducer';
import { toast } from 'react-toastify';
const VenderModal = ({ open, onClose, currentVender }) => {
    const [vendername, setVendername] = useState('');
    const [vaddress, setVaddress] = useState('');
    const [panno, setPanno] = useState('');
    const [accountno, setAccountno] = useState('');
    const [mobileno, setMobileno] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
   
    const [active, setActive] = useState(true);
    const dispatch = useDispatch();

    useEffect(() => {
        if (currentVender!=null) {
            setVendername(currentVender.vendername);
            setVaddress(currentVender.vaddress);
            setPanno(currentVender.panno);
            setAccountno(currentVender.accountno);
            setMobileno(currentVender.mobileno);
            setEmail(currentVender.email);
            setDescription(currentVender.description);    
            setActive(currentVender.active);
        }
    }, [currentVender]);

    const handleSave = () => {
        const vender = { vendername,vaddress,panno,accountno,mobileno,email, description, active };
        if (currentVender) {
            dispatch(updateVender({ id: currentVender.id, vender })).then(() => {
                toast.success('Vender updated successfully!');   
            }).catch((error) => {
                toast.error('Failed to add Vender!');
            });
          
        } else {
            dispatch(addVender(vender)).then(() => {
            toast.success('Vender addded successfully!');   
        }).catch((error) => {
            toast.error('Failed to add Vender!');
        });
            
        }
        onClose();
    };

    

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={{ p: 3, bgcolor: 'background.paper', margin: 'auto', width: 500 }}>
                <Typography variant='h6' color={'darkblue'}>Manage Vender</Typography><hr/>
<Grid container spacing={2}>
    <Grid item size={6}>  <TextField
                    fullWidth
                    label="Vender Name"
                      autoComplete='off'
                    value={vendername}
                    onChange={(e) => setVendername(e.target.value)}
                    margin="normal"
                /></Grid>  <Grid item size={6}>   <TextField
                fullWidth
                label="Vender Address"
                  autoComplete='off'
                value={vaddress}
                onChange={(e) => setVaddress(e.target.value)}
                margin="normal"
            /></Grid>
            <Grid item size={6}> <TextField
                    fullWidth
                    label="PAN No."
                      autoComplete='off'
                    value={panno}
                    onChange={(e) => setPanno(e.target.value)}
                    margin="normal"
                /></Grid><Grid item size={6}><TextField
                fullWidth
                label="Account No."
                  autoComplete='off'
                value={accountno}
                onChange={(e) => setAccountno(e.target.value)}
                margin="normal"
            /></Grid>
            <Grid item size={6}> <TextField
                    fullWidth
                      autoComplete='off'
                    label="Mobile No."
                    value={mobileno}
                    onChange={(e) => setMobileno(e.target.value)}
                    margin="normal"
                /></Grid>
            <Grid item size={6}> <TextField
                    fullWidth
                      autoComplete='off'
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    margin="normal"
                /></Grid>
</Grid>

               
<Grid item size={6}>
                <TextField
                    fullWidth
                    autoComplete='off'
                    label="Description"
                    value={description}
                    multiline
          rows={3}
                    onChange={(e) => setDescription(e.target.value)}
                    margin="normal"
                /></Grid>
              
                <FormControlLabel
                    control={<Switch checked={active} onChange={(e) => setActive(e.target.checked)} />}
                    label="Active"
                />
                <Button variant="contained" onClick={handleSave}>Save</Button>
            </Box>
        </Modal>
    );
};



export default VenderModal