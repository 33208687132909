import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Dashboard from '../Ceo/components/Dashboard';
import AdminApp from '../Ceo/components/AdminApp';
import Ceo from '../Ceo/components/Ceo';
import File from '../Ceo/components/File';
import ProjectList from '../Ceo/components/ProjectList';
import CeoProjects from '../Ceo/components/CeoProjects';
import FileUpload from '../Ceo/components/Fileupload';
import Video from '../Ceo/components/Video';
import CeoProjectsInfra1 from '../Ceo/components/CeoProjectsInfra1';
import CeoProjectsInfra2 from '../Ceo/components/CeoProjectsInfra2';
import CeoProjectsInfra1Complete from '../Ceo/components/CeoProjectsInfra1Complete';
import CeoProjectsInfra1Pending from '../Ceo/components/CeoProjectsInfra1Pending';
import CeoProjectsInfra1Uprogress from '../Ceo/components/CeoProjectsInfra1Uprogress';


const CeoRouter = () => {
  return (
    <div>
      <Routes>
            <Route path='/*' element={<Ceo />}/>
            <Route path='/dashboard' element={<Dashboard />}/>
           
            <Route path='/fileup' element={<File />}/>
            
            <Route path='/projects' element={<CeoProjects />}/>
            <Route path='/projectsInfra1' element={<CeoProjectsInfra1 />}/>
            <Route path='/projectsInfra1Complete' element={<CeoProjectsInfra1Complete />}/>
            <Route path='/projectsInfra1Uprogress' element={<CeoProjectsInfra1Uprogress />}/>
            <Route path='/projectsInfra1Pending' element={<CeoProjectsInfra1Pending />}/>
            <Route path='/projectsInfra2' element={<CeoProjectsInfra2 />}/>
            
            <Route path='/fileup/:workId' element={<FileUpload />}/>

        </Routes>
    </div>
  );
}

export default CeoRouter;
