import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createEmployee, fetchEmployeesByIdName, updateEmployee } from '../../Redux/employeeSlice';
import { TextField, Button, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogTitle, Grid2 as Grid, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio, Stack, InputLabel, Box, Autocomplete, Switch } from '@mui/material';

import { fetchOrgtypes } from '../../Redux/otReducer';
import { fetchDesigs } from '../../Redux/desigReducer';
import { fetchPosts } from '../../Redux/postReducer';


const EmployeeForm = ({ open, handleClose, employee}) => {

  const employees1 = useSelector((state) => state.employees.employeesByIdName);
    const orgus = useSelector((state) => state.orgus.orgus);  
    const desigs = useSelector((state) => state.desigs.desigs);
    const posts = useSelector((state) => state.posts.posts);
   
  const [formData, setFormData] = useState({
    employeeCode: '',
    name: '',
    gender: '',
    mobile: '',
    email: '',
    designationId: '',
    postId: '',
    reportingToIds: [],
    orguId: '',
    
  });
 

  const dispatch = useDispatch();
  useEffect(()=>{
   dispatch(fetchEmployeesByIdName());
    dispatch(fetchOrgtypes());
   
    dispatch(fetchDesigs());
    dispatch(fetchPosts());
},[dispatch])


useEffect(() => {
  // console.log(JSON.stringify(employee, null, 2)+"this is:");
  
  
  if (employee) {
    setFormData(employee);
  }
}, [employee]);
  // useEffect(() => {
  //   if (employee) {
  //     setFormData({
  //       employeeCode: employee.employeeCode,
  //       name: employee.name,
  //       gender: employee.gender,
  //       mobile: employee.mobile,
  //       email: employee.email,
  //       orguId: employee.orguId,
  //       designationId: employee.designationId,
  //       postId: employee.postId,
  //       reportingToIds: employee.reportingToIds,
        // division: employee.division,
  //     });
  //   }
  // }, [employee]);
  // const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };

  // const regex = /^[987]\d{9}$/;

  // const handleKeyUp = (event) => {
  //   const inputValue = event.target.value;

    // Remove non-numeric characters
    // const numericValue = inputValue.replace(/\D/g, '');

    // setFormData(numericValue);

    // Validate input
  //   if (numericValue.length === 10 && regex.test(numericValue)) {
  //     setError('');
  //   } else if (numericValue.length > 10) {
  //     setError('Number must be exactly 10 digits.');
  //   } else if (numericValue.length >= 3 && !numericValue.startsWith('987')) {
  //     setError('Number must start with 987.');
  //   } else {
  //     setError('Invalid mobile number.');
  //   }
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (employee!=null) {
      dispatch(updateEmployee({ id: employee.id, employee: formData }));
      dispatch(fetchEmployeesByIdName());
      dispatch(fetchOrgtypes());
   
      dispatch(fetchDesigs());
      dispatch(fetchPosts());
    } else {
      dispatch(createEmployee(formData));
      dispatch(fetchEmployeesByIdName());
      dispatch(fetchOrgtypes());
   
      dispatch(fetchDesigs());
      dispatch(fetchPosts());
    }
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{employee ? 'Edit Employee' : 'Create Employee'}</DialogTitle>
      <DialogContent>

        <Grid container spacing={2}>
<Grid item size={6}>
<TextField name="employeeCode" label="Employee Code" value={formData.employeeCode} onChange={handleChange} fullWidth />
</Grid>
      
        <Grid item size={6}>   <TextField name="name" label="Name" value={formData.name} onChange={handleChange} fullWidth /></Grid>
        <Grid item size={6}>  <TextField
        autoComplete='off'
        name="mobile" label="Mobile" value={formData.mobile} onChange={handleChange} fullWidth 
       
        /></Grid>
        <Grid item size={6}><TextField name="email" label="Email" value={formData.email} onChange={handleChange} fullWidth /></Grid>
        <Grid item size={12}> 
            <FormControl component="fieldset">
          
            <FormLabel component="legend">Gender</FormLabel>
            <Stack direction={'row'}>  <RadioGroup  row aria-label="gender" name="gender" value={formData.gender} onChange={handleChange}>
                <FormControlLabel value="male" control={<Radio />} label="Male" />
                <FormControlLabel value="female" control={<Radio />} label="Female" />
                <FormControlLabel value="other" control={<Radio />} label="Other" />
            </RadioGroup></Stack>
          
        </FormControl>
        </Grid>
        <Grid item size={6}>
             <FormControl fullWidth>
            <InputLabel id="category-select-label">Divisions</InputLabel>
            <Select
            name='orguId'
            autoComplete='off'
                labelId="category-select-label"
                id="category-select"
                label="Division" 

                onChange={handleChange}
                value={formData.orguId?formData.orguId:''}
            >
                {orgus.map((ou) => (
                    <MenuItem key={ou.id} value={ou.id}>
                        {ou.orguname}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
        </Grid>
        <Grid item size={6}>
             <FormControl fullWidth>
            <InputLabel id="category-select-label">Designation</InputLabel>
            <Select
            autoComplete='off'
            name='designationId'
                labelId="category-select-label"
                id="category-select"
                label="Designation"
                value={formData.designationId?formData.designationId:''}
                // onChange={(e) => setDesig(e.target.value)}
                onChange={handleChange}
            >
                {desigs.map((dg) => (
                    <MenuItem key={dg.id} value={dg.id}>
                        {dg.designame}
                    </MenuItem>
                ))}
            </Select>
        
        </FormControl></Grid>
        <Grid item size={6}>
             <FormControl fullWidth>
            <InputLabel id="category-select-label">Post</InputLabel>
            <Select
            autoComplete='off'
            name='postId'
                labelId="category-select-label"
                id="category-select"
                label="Post"
               value={formData.postId?formData.postId:''}
                onChange={handleChange}
            >
                {posts.map((pt) => (
                    <MenuItem key={pt.id} value={pt.id}>
                        {pt.postname}
                    </MenuItem>
                ))}
            </Select>
        
        </FormControl></Grid>
      <Grid item size={6}>
        <Box height={5}/>

        <Autocomplete
          multiple
          fullWidth
          options={employees1}
          getOptionLabel={(option) => option.name}
          onChange={(event, value) => setFormData({ ...formData, reportingToIds: value.map(v => v.id) })}
          renderInput={(params) => <TextField {...params} label="Reporting To" />}
        /></Grid>
         {/* <Grid item size={6}>
          <FormControlLabel
            control={
              <Switch
              name='active'
              value={formData.active}
                checked={active}
                onChange={handleChange}
              />
            }
            label="Active"
          /></Grid> */}
        </Grid>
      
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>{employee ? 'Update' : 'Create'}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EmployeeForm;