import React, { uheEffect, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWorks,
  
} from "../../Redux/workReducer";
import {
  Button,
  
  ListWorkText,
  IconButton,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  ListItem,
  ListItemText,
} from "@mui/material";

import { DataGrid, GridActionsCellItem, GridToolbar } from "@mui/x-data-grid";
import { Add } from "@mui/icons-material";
import { Link } from "react-router-dom";

const WorkList = () => {
  const dispatch = useDispatch();
  const works = useSelector((state) => state.works.works);
  

 

  useEffect(() => {
    console.log(works);

    dispatch(fetchWorks());
  }, [dispatch]);

  // const handleEdit = (post) => {
  //   setSelectedWork(post);
  //   setModalOpen(true);
  // };

  // const handleDeleteWork = (id) => {
  //   dispatch(deleteWork(id))
  //     .then(() => {
  //       toast.success("Iten deleted successfully!");
  //     })
  //     .catch((error) => {
  //       toast.error("Failed to add post!");
  //     });
  // };

  // const handleAdd = () => {
  //   setModalOpen(true);
  //   setSelectedWork(null);
  // };
  // const handleChangeStatus = (id, active) => {
  //   dispatch(changeWorkStatus({ id, active }))
  //     .then(() => {
  //       toast.success("Work status updated successfully!");
  //     })
  //     .catch((error) => {
  //       toast.error("Failed to update post status!");
  //     });
  // };

  // const handleDialogOpen = (type, workId) => {
  //   setDialogType(type);
  //   setCurrentWorkId(workId);
  //   setDialogOpen(true);
  // };

  // const handleDialogClose = () => {
  //   setDialogOpen(false);
  //   setCurrentWorkId(null);
  //   setDialogType("");
  // };

  // const handleConfirmAction = () => {
  //   if (dialogType === "delete") {
  //     handleDeleteWork(currentWorkId);
  //   }
  //   handleDialogClose();
  // };

  const columns = [
    { field: "id", headerName: "ID",  },
    { field: "workname", headerName: "Work Name",
      renderCell: (params) => 
        
        <Link to={`/se/svisit/${params.row.id}`}>{params.row.workname}</Link>
    },
    { field: "wstatus", headerName: "Work Status",  },
    { field: "vender", headerName: "Agency Name",
      renderCell: (params) => params?.row?.vender?.vendername ||'none',
    },
    { field: "startDate", headerName: "Start Date"},
    { field: "timeLimit", headerName: "Time Limit"},
    { field: "dlp", headerName: "DLP"},
    { field: "sdDateComplition", headerName: "Date of Complition"},
    { field: "srDateComplition", headerName: "Rivised Date of Complition"},
    { field: "budgetDYear", headerName: "Budget During Year"},
    { field: "phyStatus", headerName: "Physical Work Status(%) "},
    { field: "finStatus", headerName: "Finacial Work Status(%)"},   

    { field: "description", headerName: "Description" },
    // {
    //   field: "Action1",
    //   headerName: "Status",
    //   description: "This column has a value getter and is not sortable.",
    //   IconButton: <DeleteIcon />,

     
    //   renderCell: ({ row }) => <Switch checked={row.active} onChange={(e) => handleChangeStatus(row.id, e.target.checked)}/>,
 
    // },

    // {
    //   field: "Action",
    //   headerName: "Action",
    //   description: "This column has a value getter and is not sortable.",
      // IconButton: <EditIcon />,
     
      // renderCell: ({ row }) => (
      //   <IconButton onClick={() => handleEdit(row)}>
      //     <EditIcon />
      //   </IconButton>
      // ),
    // },

    // {
      //   field: 'Action',
      // headerName: 'Action',
      // description: "This column has a value getter and is not sortable.",
      // IconButton: <DeleteIcon />,

      
      // renderCell: ({ row }) => (
      //   <IconButton onClick={() => handleDialogOpen("delete", row.id)}>
      //     <DeleteIcon />
      //   </IconButton>
      // ),
    // },
  ];

  return (
    <div>
      {/* <Button variant="contained" onClick={handleAdd}>
        Add Project/Work <Add/>
      </Button> */}

      <div style={{ height: 'auto', width: '100%'  }}>
        <DataGrid
          rows={works}
          columns={columns}
         getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 50, 100]}
          slots={{ toolbar: GridToolbar }}
          getRowHeight={() => 'auto'}
          //checkboxSelection
        />
      </div>

      {/* <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Confirm Action</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to {dialogType} this product?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmAction} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog> */}
      {/* <WorkModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        currentWork={selectedWork}
      /> */}
    </div>
  );
};




export default WorkList