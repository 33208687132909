import React from 'react'
import HomeCarousel from './components/HomeCarousel/HomeCarousel'
import HomeSectionCarouse from './components/HomeSectionCarouse/HomeSectionCarouse'
import Footer from './components/Footer'

import { Box } from '@mui/material'
import Navbar from './components/Navbar'
import m1 from '../img/body_bg.jpg'

const HomePage = () => {
  return (
    <div>
         <Navbar />
         <Box height={70} />

      <div  className='h-[50vh] -z-10'>

        <HomeCarousel/>
        </div>
   
   <Footer/>

    </div>
  )
}

export default HomePage