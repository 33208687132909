import React, { useEffect } from "react";
import Navbar from "../../Pages/components/Navbar";
import Sidenav from "./Sidenav";
import "./Mystype.css";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Stack,
  Typography,
} from "@mui/material";
import CountUp from "react-countup";
import { Grid2 as Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchVenders } from "../../Redux/venderReducer";

import {Send} from '@mui/icons-material';
import { fetchWorksCreatedBy } from "../../Redux/workReducer";
const Xendashboard = () => {

  const navigate=useNavigate();

  const dispatch = useDispatch();
  const venders = useSelector((state) => state.venders.venders);
  const works = useSelector((state) => state.works.worksCreatedBy);

  useEffect(() => {
   // console.log(venders);

    dispatch(fetchVenders());
    dispatch(fetchWorksCreatedBy());
  }, [dispatch]);
  return (
    <div>
      <Navbar /> <Box height={70} />
      <Box sx={{ display: "flex" }}>
        <Sidenav />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Typography variant="h4" align="center" py={2}>
            XEN Dashbaord
          </Typography>
          <hr />
          <Box height={20} />

          <Grid spacing={2} container>
            <Grid size={6}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Venders
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/xen/vender")}
                  >
                 View All Venders<Send/>:{" "}
                    {/* <CountUp delay={0.3} end={orgus.length} duration={2.9} /> */}
                    <CountUp delay={0.3} end={venders.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
            <Grid size={6}>
              <Card>
                <div className="cand-box">
                  <Typography
                    sx={{
                      color: "darkgreen",
                      backgroundColor: "#e3effd",
                      "&:hover": {
                        color: "green",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h4"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                  >
                    Projects/Works
                  </Typography>
                  <Box height={20}/>
                  <Typography
                    mx={5}
                    sx={{
                      "&:hover": {
                        color: "darkblue",
                        backgroundColor: "yellow",
                        borderRadius: 10,
                      },
                    }}
                    gutterBottom
                    variant="h5"
                    style={{ borderRadius: "10" }}
                    component="div"
                    align="center"
                    onClick={() => navigate("/xen/projectsxen")}
                  >
                 View All Projects/Works<Send/>:{" "}
                    {/* <CountUp delay={0.3} end={orgus.length} duration={2.9} /> */}
                    <CountUp delay={0.3} end={works.length} duration={2.9} />
                  </Typography>
                </div>
              </Card>
            </Grid>
          </Grid>

          {/* <Grid container spacing={2}>
          <Grid item xs={8}>
              <Stack spacing={2} direction={"row"}>
            <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
             
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
        Projects Created By Xen:<br/><CountUp delay={0.3} end= "19" duration={2.9} />
                </Typography>
                {/* <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with
                  over 6,000 species, ranging across all continents except
                  Antarctica
                </Typography> */}
          {/* </CardContent>
            
            </Card>
            <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
             
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
             Projects  Under Progress :<br/><CountUp delay={0.3} end= "10" duration={2.9} />
                </Typography> */}
          {/* <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with
                  over 6,000 species, ranging across all continents except
                  Antarctica
                </Typography> */}
          {/* </CardContent>
            
            </Card>
            <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>
             
              <CardContent>
                <Typography gutterBottom variant="h5" component="div">
             Completed Projects  :<br/><CountUp delay={0.3} end= "5" duration={2.9} />
                </Typography>
               
              </CardContent>
            
            </Card>
            </Stack>
          </Grid>
          <Grid item xs={4}>
              <Stack spacing={2}>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
               Delay Projects :<br/><CountUp delay={0.3} end= "3" duration={2.9} />
               </Typography>
              
             </CardContent>
           
           </Card>
          <Card sx={{ position: "relative" ,bgcolor:'#642B2E' ,color:"white",maxWidth: 345 }}>               
             <CardContent>
               <Typography gutterBottom variant="h5" component="div">
            Pending Projects:<br/><CountUp delay={0.3} end= "1" duration={2.9} />
               </Typography> */}
          {/* <Typography variant="body2" color="text.secondary">
                 Lizards are a widespread group of squamate reptiles, with
                 over 6,000 species, ranging across all continents except
                 Antarctica
               </Typography> */}
          {/* </CardContent>
           
           </Card>
           </Stack>
          </Grid>
          <Box height={20} />
          
        </Grid> */}
        </Box>
      </Box>
    </div>
  );
};

export default Xendashboard;
