import { BrowserRouter, Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CeoRouter from "./Routers/CeoRouter";
import AdminRouter from "./Routers/AdminRouter";
import XenRouter from "./Routers/XenRouter";
import HomePage from "./Pages/HomePage";
import Error from "./Pages/components/Error";
import PublicRoutes from "./Routers/PublicRoutes";
import PrivateRoutes from "./Routers/PrivateRoutes";
import React, { useEffect, useState } from "react";
import { isLoggedIn as checkLoggedIn } from "./Pages/components/Auth";
import ManageRoutes from "./Routers/ManageRoutes";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "./Redux/authSlice";

import m1 from './img/body_bg.jpg'

function App() {

  const isUserLoggedIn = useSelector(store => store.auth.isUserLoggedIn);
  const isLoading = useSelector(store => store.auth.isLoading);
  const roles = useSelector(store => store.auth.user?.roles);
const navigate=useNavigate()
useEffect(()=>{
  if(roles && roles==="CONTRACTOR"){
    navigate("/contractor")
  }
 else if(roles && roles==="ADMIN"){
    navigate("/admin")
  }
  else if(roles && roles==="CEO"){
    navigate("/ceo")
  }
  else if(roles && roles==="CE"){
    navigate("/ce")
  }
  else if(roles && roles==="SE"){
    navigate("/se")
  }
  else if(roles && roles==="XEN"){
    navigate("/xen")
  }
  else if(roles && roles==="JE"){
    navigate("/je")
  }
  else{
    navigate("/")
  }
},[roles])

const dispatch=useDispatch();
  //const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
if(!isUserLoggedIn && checkLoggedIn()){
 // setIsLoggedIn(checkLoggedIn());
  dispatch(getUser())
}

     
    //console.log(isLoggedIn);
    
  } );
  return (
    <>
    <div>
    {
      !isLoading&&  <ManageRoutes />
    }
   </div>
    </>
    // <div>
    //   <Routes>
    //     <Route path="/" exact element={<HomePage />} />
    //     {(isLoggedIn ? PrivateRoutes() : PublicRoutes())} 
  //       <Route path='/about' exact element={<About />}/>
  // <Route path='/settings' exact element={<Settings />}/>   

      //  <Route path="*" exact element={<Error />} /> 
      // </Routes>
      // <ToastContainer />
    // </div> 
  );
}

export default App;
