import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { useSelector, useDispatch } from 'react-redux';
import { fetchEmployees, deleteEmployee, changeEmployeeStatus } from '../../Redux/employeeSlice';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, Switch } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EmployeeForm from './EmployeeForm';
import { Add } from '@mui/icons-material';
import { toast } from "react-toastify";

const EmployeeTable = () => {
  const dispatch = useDispatch();
  const employeeList = useSelector((state) => state.employees.employees);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [openFormDialog, setOpenFormDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  useEffect(() => {
   // console.log(employeeList);
    
    dispatch(fetchEmployees());
  },[dispatch]);

  const handleEdit = (employee) => {
    setSelectedEmployee(employee);
    setOpenFormDialog(true);
  };

  const handleDelete = (employee) => {
    setSelectedEmployee(employee);
    setOpenDeleteDialog(true);
  };

  const confirmDelete = () => {
    dispatch(deleteEmployee(selectedEmployee.id));
    setOpenDeleteDialog(false);
  };

  const handleChangeStatus = (id, active) => {
    dispatch(changeEmployeeStatus({ id, active }))
      .then(() => {
        toast.success("Employee status updated successfully!");
        dispatch(fetchEmployees());
      })
      .catch((error) => {
        toast.error("Failed to update Employee status!");
      });
  };

  const columns = [
    { field: 'id', headerName: 'Sr. No.', width: 150 },
    { field: 'employeeCode', headerName: 'Employee Code', width: 150 },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'gender', headerName: 'Gender', width: 100 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'designation', headerName: 'Designation', width: 200,
      renderCell: (params) => { 
        return params?.row?.designation?.designame },
     },
    { field: 'orgu', headerName: 'Division', width: 200 ,

      renderCell: (params) => { 
        return params?.row?.orgu?.orguname },
    },
    { field: 'post', headerName: 'Post Name', width: 200,
      renderCell: (params) => { 
        return params?.row?.post?.postname },
     },
    {
      field: 'reportingTo',
      headerName: 'Assigned Employees',
      width: 300,
      renderCell: (params) => { 
             return params?.row?.reportingTo?.map(r=>r.name).join(', ') },
            },
    // { field: 'active', headerName: 'Status', width: 200 },

    // { field: 'designame', headerName: 'Designation', width: 200, renderCell: (params) => params?.row?.designation?.designame},
    // { field: 'postname', headerName: 'Post Name', width: 200 },
    // { field: 'orguname', headerName: 'Division Name', width: 200 },


    // { field: 'designation', headerName: 'Designation', width: 150, renderCell: (params) => params?.row?.designation?.designame },
    // { field: 'post', headerName: 'Post', width: 150, renderCell: (params) => params?.row?.post?.postname },
    // { field: 'orgu', headerName: 'Division', width: 150, renderCell: (params) => params?.row?.orgu?.orguname },
    //  { field: 'reportingTo', headerName: 'Reporting Officer', width: 200,
    //    renderCell: (params) => {              
    //     return params?.row?.reportingTo?.map(r=>r.name).join(', ') },
    //    },
      //   {s
      //   console.log(params.row);        
      //   return params?.row?.reportingTo?.map(r=>r.name).join(', ')      
      //  }
     // },
     {
      field: "Action",
      headerName: "Status",
      description: "This column has a value getter and is not sortable.",
      // IconButton: <DeleteIcon />,

      width: 60,
      renderCell: ({ row }) => (
        <Switch
          checked={row.active}
          onChange={(e) => handleChangeStatus(row.id, e.target.checked)}
        />
      ),
    },

    {
      field: "Action2",
      headerName: "Edit",
      description: "This column has a value getter and is not sortable.",
      IconButton: <EditIcon />,
      width: 10,
      renderCell: (param) => {       
return <>
<IconButton onClick={() => handleEdit(param.row)}>
          <EditIcon />
        </IconButton>
</>
      }
        
    
    },
    {
      field: 'Action3',
    headerName: 'Delete',
   description: "This column has a value getter and is not sortable.",
   IconButton: <DeleteIcon />,

   width: 10,
   renderCell: (params) => (
     <IconButton onClick={() => handleDelete(params.row)}>
       <DeleteIcon />
     </IconButton>
   ),
 },
     
    // {
    //   field: 'actions',
    //   headerName: 'Actions',
    //   width: 150,
    //   renderCell: (params) => (
    //     <>
        
    //       <IconButton color="primary" onClick={() => handleEdit(params.row)}>
    //         <EditIcon />
    //       </IconButton>
    //       <IconButton color="secondary" onClick={() => handleDelete(params.row)}>
    //         <DeleteIcon />
    //       </IconButton>
    //     </>
    //   ),
    // },
  ];
  const handleAdd = () => {
    setOpenFormDialog(true);
   
  };
  return (
    <>

<Button variant="contained" onClick={handleAdd}>
        Add Employee <Add/>
      </Button>
      <div style={{ height: 700, width: '100%' }}>
        <DataGrid rows={employeeList} columns={columns} pageSize={5}
         slots={{ toolbar: GridToolbar }}
        />
      </div>

      {/* Employee Form Dialog */}
      <EmployeeForm
        open={openFormDialog}
        handleClose={() => setOpenFormDialog(false)}
        employee={selectedEmployee}
       // designations={designations}
       // posts={posts}
       // employees={employees}
      />

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)}>
        <DialogTitle>Delete Employee</DialogTitle>
        <DialogContent>Are you sure you want to delete {selectedEmployee?.name}?</DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>Cancel</Button>
          <Button onClick={confirmDelete} color="secondary">Delete</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EmployeeTable;