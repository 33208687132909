import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import { useAppStore } from '../../appStore';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import AuthModal from './Auth/AuthModal';
import { useEffect } from 'react';
import { getUser, logout } from '../../Redux/authSlice';
import { useDispatch,useSelector } from 'react-redux';
import { getCookie } from '../../Redux/apiConfig';
import { fetchUsersDetailsById } from '../../Redux/userReducer';

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
 
}));


const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function Navbar() {

  
 

  const [anchorEl, setAnchorEl] = React.useState(null);
  //const [anchorE1, setAnchorE1] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

const updateOpen=useAppStore((state)=>state.updateOpen);
const dopen=useAppStore((state)=>state.dopen);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };
  const handleLogout = () => {
    dispatch(logout());
    
    handleMobileMenuClose();
    handleMenuClose();
    navigate("/")
  };


  const handleMenuProfile = () => {
   
    
    handleMobileMenuClose();
    handleMenuClose();
    navigate("/admin/profile")
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
   
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={handleMenuProfile}>Profile</MenuItem> */}
      {/* <MenuItem onClick={()=>navigate("/admin/profile")}>Profile</MenuItem> */}
      {/* <MenuItem onClick={handleMenuClose}>My account</MenuItem> */}
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>

    
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem> */}
      {/* <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        {/* <p onClick={()=>alert("/admin/profile")}>Profile</p> */}
        
      </MenuItem>
      <MenuItem onClick={handleLogout}>  Logout </MenuItem>
    </Menu> 
  );
  const [openAuthModal, setOpenAuthModal] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpenAuthModal(true);
  };

  const handleClose=()=>{
    setOpenAuthModal(false);
  }
  
  const [active, setActive] = React.useState(true);


//  const jwt = localStorage.getItem("jwt");
const jwt= getCookie('jwt');


  const {auth} = useSelector(store => store);
const navigate=useNavigate();
const location=useLocation();
const dispatch=useDispatch();

  useEffect(() => {
    if (jwt) {
      dispatch(getUser(jwt));
     
    }
  }, [jwt, auth.jwt]);
  useEffect(() => {

   if (auth?.user) {
     // navigate("/xen/")
      
      // console.log(auth?.user.email);
      
     handleClose();
   }


    // if (auth?.user?.roles=='ADMIN') {
    //   navigate("/admin/")
      
    //   console.log(auth?.user?.roles);
      
    //   handleClose();
    // }
    // else if (auth?.user?.roles=='CEO') {
    //   navigate("/ceo")
      
    //   console.log(auth?.user?.roles);
      
    //   handleClose();
    // }
    // else if (auth?.user?.roles=='CONTRACTOR') {
    //   navigate("/xen")
      
    //   console.log(auth?.user?.roles);
      
    //   handleClose();
    // }
    // else if (auth?.user?.roles=='CONTRACTOR') {
    //   navigate("/contractor")
      
    //   console.log(auth?.user?.roles);
      
    //   handleClose();
    // }
    // else {
    //   navigate("/")
      
    //   console.log(auth?.user?.roles);
      
    //   handleClose();
    // }
    if (location.pathname === "/login" || location.pathname === "/register") {
      navigate(-1);
    }
  }, [auth.user]);

  //const dispatch = useDispatch();
  const getusersdetailsbyid = useSelector((state) => state.users.usersById);
  useEffect(() => {
   
    dispatch(fetchUsersDetailsById());
  }, [dispatch]);

  return (
    <div>
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" color='warning'>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}

            onClick={()=>updateOpen(!dopen)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { xs: 'none', sm: 'block' } }}
          >
            Project Monitoring...
          </Typography>


          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          <Box sx={{ flexGrow: 1 }} />
          <Typography variant='h4' color='lightblue'>{auth?.user?.fullName?<div>Welcome : {auth.user.email}
         
         [{getusersdetailsbyid.name}-{getusersdetailsbyid.designame}-{getusersdetailsbyid.orgname}]
          </div>:<div >...</div>}</Typography>
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            
          {
            auth?.user?.fullName?<IconButton
            size="large"
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton> :     <Button  onClick={handleOpen}
                      className="-m-2 block p-2 font-medium text-gray-900"
                    >
                       {active ? "Signin" : "Singup"}
                       {/* Login */}
                    </Button>
          }
            

       

            
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
    <AuthModal handleClose={handleClose} open={openAuthModal} />
    </div>
  );
}
