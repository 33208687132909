import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




import PublicRoutes from "./PublicRoutes";
import PrivateRoutes from "./PrivateRoutes";
import React, { useEffect, useState } from "react";
import HomePage from "../Pages/HomePage";
import Error from "../Pages/components/Error";
import { useSelector } from "react-redux";
import m1 from '../img/body_bg.jpg'


function ManageRoutes({}) {
 // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isUserLoggedIn = useSelector(store => store.auth.isUserLoggedIn);
  
  return (
    <div>
      <Routes>
        <Route path="/" exact element={<HomePage />} />
        {(isUserLoggedIn ? PrivateRoutes() : PublicRoutes())} 
        {/* <Route path='/about' exact element={<About />}/>
  <Route path='/settings' exact element={<Settings />}/>   */}

        {/* <Route path="*" exact element={<Error />} /> */}
        <Route path="*" exact element={<Error />} /> 
      </Routes>
      <ToastContainer />
    </div>
  );
}


export default ManageRoutes