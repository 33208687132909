import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  Box,
  TextField,
  Switch,
  FormControlLabel,
  Typography,
  Grid2 as Grid,
  MenuItem,
  InputLabel,
  Select,
  FormControl,
} from "@mui/material";
import { addUser, updateUser } from "../../Redux/userReducer";
import { toast } from "react-toastify";
import { fetchEmployeesByIdName } from "../../Redux/employeeSlice";
const UserModal = ({ open, onClose, currentUser }) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [roles, setRoles] = useState();
  const [employeeId, setEmployeeId] = useState();

  const [active, setActive] = useState(true);
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.employees.employees);
  const employeesByIdName = useSelector((state) => state.employees.employeesByIdName);

  useEffect(() => {
    dispatch(fetchEmployeesByIdName());
  }, [dispatch]);

  useEffect(() => {
    if (currentUser) {
      setFullName(currentUser.fullName);
      setEmail(currentUser.email);
      setPassword(currentUser.password);
      setRoles(currentUser.roles);
      setActive(currentUser.active);
      setEmployeeId(currentUser.setEmployeeId);
    }
  }, [currentUser]);

  const handleSave = () => {
    const user = { fullName, email, password, roles, employeeId, active };
    if (currentUser) {
      dispatch(updateUser({ id: currentUser.id, user }))
        .then(() => {
          toast.success("User updated successfully!");
        })
        .catch((error) => {
          toast.error("Failed to add User!");
        });
    } else {
      dispatch(addUser(user))
        .then(() => {
          toast.success("User addded successfully!");
        })
        .catch((error) => {
          toast.error("Failed to add User!");
        });
    }
    onClose();
  };

  const currencies = [
    {
      value: "CEO",
      label: "Cheif Executive Office",
    },
    {
      value: "CE",
      label: "Cheif Engineer",
    },
    {
      value: "SE",
      label: "SUPERIDENT Engineer",
    },
    {
      value: "XEN",
      label: "Executive Enginner",
    },
    {
      value: "SDO",
      label: "Sub Division Enginner",
    },
    {
      value: "JE",
      label: "Junier Enginner",
    },
    {
      value: "CONTRACTOR",
      label: "Contractor",
    },
    {
      value: "ADMIN",
      label: "Administrator",
    },
  ];

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{ p: 3, bgcolor: "background.paper", margin: "auto", width: 500,marginTop:10 }}
      >
        <Grid container spacing={3}>
            <Box height={10}/>
          <Typography variant="h6" color={"darkblue"} align="center">
            Manage User
          </Typography>
          <hr />
          <Grid item size={12}>
            <TextField
              fullWidth
              label="User Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              margin="normal"
            />
          </Grid>
          <Grid item size={12}>
            <TextField
              required
              id="email"
              name="email"
              label="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              autoComplete="off"
           
            />
          </Grid>
          <Grid item size={12}>
            <TextField
              required
              id="password"
              name="password"
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              autoComplete="off"
            />
          </Grid>
          <Grid item size={12}>
            <TextField
              id="roles"
              name="roles"
              select
              label="Select Role"
             // defaultValue="JE"
              value={roles}
              onChange={(e) => setRoles(e.target.value)}
              helperText="Please select  Role"
              fullWidth
            >
              {currencies.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item size={12}>
            <FormControl fullWidth>
              <InputLabel id="category-select-label" fullWidth sx={{width:200}}>
                Select Employee{" "}
              </InputLabel>
              <Select
              fullWidth
                autoComplete="off"
                labelId="category-select-label"
                id="category-select"
                label="Employee"
                value={employeeId}
                onChange={(e) => setEmployeeId(e.target.value)}
              >
                {employeesByIdName.map((emp) => (
                  <MenuItem key={emp.id} value={emp.id}>
                    {emp.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
                 <Grid item size={6}>
          <FormControlLabel
            control={
              <Switch
                checked={active}
                onChange={(e) => setActive(e.target.checked)}
              />
            }
            label="Active"
          /></Grid> 
          <Grid item size={3}></Grid>
          <Grid item size={3}>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default UserModal;
