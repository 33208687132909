import React, { useEffect } from 'react';
import { fetchWorkAssignToEmployees, fetchWorksCreatedBy } from '../../Redux/workReducer';
import { useDispatch, useSelector } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid';
import { FormatAlignJustify } from '@mui/icons-material';

const AssignProTeamList = () => {
  const dispatch=useDispatch();
  const workAssignToEmployeesList = useSelector(state => state.works.workAssignToEmployeesList);
  useEffect(() => {
    console.log(workAssignToEmployeesList);

    dispatch(fetchWorkAssignToEmployees());
  }, [dispatch]);

    const columns = [
        { field: 'id', headerName: 'Work Id', width: 150 },
        { field: 'workname', headerName: 'Work Name', width: 500 ,
          renderCell: (params) => (
            <div
              style={{
                textAlign: "justify", // Inline style for justification
                whiteSpace: "normal", // Allow text wrapping
                wordBreak: "break-word", // Break long words
              }}
            >
              {params.value}
            </div>
          ),

         },
        // { field: 'employees', headerName: 'Employees', width: 300, valueGetter: (params) => 
        //     params?.row?.employees?.map(e => e.name).join(', ') },
        {
          field: 'employees',
          headerName: 'Assigned Employees',
          width: 500,
          
          renderCell: (params) => { 
                 return params?.row?.employees?.map(r=>r.name).join(', ') },
                },
    ];
  return (
    <div>
      <h1> Team List</h1>
      <div style={{ height: 'auto', width: '100%', }}>
      <DataGrid rows={workAssignToEmployeesList} columns={columns} pageSize={5} getRowId={(row) => row.id}
        getRowHeight={() => 'auto'} />
        </div>
    </div>
  );
}

export default AssignProTeamList;
