import React from 'react'
import Navbar from '../../Pages/components/Navbar'
import { Box, Typography } from '@mui/material'
import Sidenav from './Sidenav'
import ProjectList from './ProjectList'
import WorkList from './WorkList'
import XenWorkList from './XenWorkList'

const XenProjects = () => {
  return (
    <div>
    <Navbar/> <Box height={70}/>
              <Box sx={{ display: "flex" }}>
                <Sidenav />
        
                <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <Typography variant='h3' align='center'  > XEN Projects List</Typography><hr/>
                <Box height={10}/>
                
                  {/* <ProjectList /> */}
                  <XenWorkList/>
                </Box>
              </Box>
    
        </div>
  )
}

export default XenProjects