import { Send } from '@mui/icons-material';
import { Autocomplete, Box, Button, Card, CardContent, FormControl, Grid2 as Grid, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { fetchWorksByIdName, updateWorkIdName } from '../../Redux/workReducer';
import { fetchEmployeesByIdName } from '../../Redux/employeeSlice';
import { useDispatch, useSelector } from 'react-redux';

const AssingProTeam = () => {

    const workByIdNames=useSelector(state=>state.works.worksIdName);
    const employeesByIdName=useSelector(state=>state.employees.employeesByIdName);
    const dispatch=useDispatch();
    const[workId,setWorkId]=useState('');
    const[employeeId,setEmployeeId]=useState('');

    const [selectedWork, setSelectedWork] = useState('');
    const [selectedEmployees, setSelectedEmployees] = useState([]);
   
  //  const handleSave=(e)=>{
  //   e.preventDefault();
  //   console.log(workId,employeeId);
    
  //   dispatch(updateWorkIdName({workId,employeeId}));
   
  //  }

   const handleSubmit = (e) => {
    e.preventDefault();
    console.log(selectedWork,selectedEmployees);
    
     dispatch(updateWorkIdName({ workId: selectedWork, employeeIds: selectedEmployees }));
};
  
    useEffect(()=>{
        dispatch(fetchWorksByIdName());
        dispatch(fetchEmployeesByIdName());
    },[dispatch])

   
  return (
    <div>
        <Box height={30}/>
      <Grid container spacing={2} >
      {/* <Grid item xs={12}><Typography variant='h4' align='center'>Assign Project to Team</Typography><hr/> </Grid> */}
        
      <Grid size={4} offset={2}>
      {/* <TextField
          fullWidth
          id="workId"
          name="workId"        
          select
          label="Select Project"
          value={workId}
          autoComplete="off"
                onChange={(e) => setWorkId(e.target.value)}          
        >
          {workByIdNames?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
             {option.fileno}-{option.workname}
            
            </MenuItem>
          ))}
        </TextField> */}
<Card sx={{minWidth:400}}>
  <CardContent>
  <InputLabel id="workIdworkId">Work/Project</InputLabel>
  <Select value={selectedWork} onChange={e => setSelectedWork(e.target.value)} sx={{width:400}} MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 200, 
              wordWrap: "break-word", 
            },
          },
        }} >
                {workByIdNames?.map(work => (
                    <MenuItem key={work.id} value={work.id}  sx={{
                      textAlign: "justify",
                      whiteSpace: "normal", // Allow text wrapping
                      wordBreak: "break-word",
                      width:500 // Ensure long words wrap
                    }} >
                        {work.workname}
                    </MenuItem>
                ))}
            </Select>
  </CardContent>
</Card>
        </Grid>
        
      <Grid item size={4} >

     
<Card sx={{minWidth:300}}>
<CardContent>
<InputLabel id="workIdworkId">Select Employees</InputLabel>
<Select
                multiple
                value={selectedEmployees}
                onChange={e => setSelectedEmployees(e.target.value)}
                style={{width:400}}
            >
                {employeesByIdName?.map(emp => (
                    <MenuItem key={emp.id} value={emp.id} sx={{width:400}}>
                        {emp.name}
                    </MenuItem>
                ))}
            </Select>
            </CardContent>
            </Card>
            <Grid size={2} >
            <Button variant="contained" 
         onClick={handleSubmit}
        >
         Assign... <Send />
        </Button>
            </Grid>


        </Grid>
        {/* <Grid item xs={6}  ></Grid>
        <Grid item xs={6}  >
            <Box xsOffset={3} alignItems={'center'} justifyContent={'center'}  >
        <Button variant="contained" 
         onClick={handleSave}
        >
         Assign... <Send />
        </Button>
        </Box>
        </Grid> */}
     
        </Grid>
        <hr />
    </div>
  );
}

export default AssingProTeam;
