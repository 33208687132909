import React from 'react';
import ProjectListInfra1 from './ProjectListInfra1';
import { Box, Typography } from '@mui/material';
import Navbar from '../../Pages/components/Navbar';

const CeoProjectsInfra1 = () => {
  return (
    <div>
      <Navbar/> <Box height={70}/>
          <Box sx={{ display: "flex" }}>
            {/* <Sidenav /> */}
    
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            <Typography variant='h3' align='center'  > CEO Projects List</Typography><hr/>
            <Box height={10}/>
            
              <ProjectListInfra1 />
            </Box>
          </Box>
    </div>
  );
}

export default CeoProjectsInfra1;
